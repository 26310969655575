.container-gpt {
	width: 100%;
	height: 100%;
	background: transparent;
	scroll-behavior: smooth;
}
.header-gpt {
	border-bottom: 1px solid #e6e6e6;
	position: relative;
	display: flex;
	flex-direction: row;
	justify-content: center;
	width: 100%;
	height: 50px;
	h1 {
		font-family: Open Sans;
		font-style: normal;
		font-weight: bold;
		font-size: 26px;
		line-height: 48px;
		color: #0c306c;
	}
	.icon-right {
		position: absolute;
		right: 20px;
		top: 15px;
	}
	.icon-left {
		position: absolute;
		left: 20px;
		top: 15px;
	}
	svg {
		cursor: pointer;
		width: 23px;
		height: 23px;
		color: var(--blue-transparent);
		transition: 0.25s;
		&:hover {
			color: var(--primary-color);
		}
	}
}
.container-chat {
	position: relative;
	padding: 56px;
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 10px;
}
.container-chat form {
	bottom: 8px;
	width: 40%;
	display: flex;
	justify-content: center;
	align-items: center;
	.text-area {
		height: 36px;
		overflow: auto;
		max-height: 50px;
		border-radius: 5px;
		background-color: transparent;
		border: none;
	}
	&:focus {
		.text-area {
			border: none;
		}
	}
}
.title-gpt-chat {
	opacity: 0;
	animation: fade-in 0.5s forwards 0.25s;
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	img {
		width: 40px;
		height: 40px;
	}
	h2 {
		font-family: Open Sans;
		font-style: normal;
		font-weight: bold;
		font-size: 18px;
		line-height: 48px;
		color: #0c306c;
	}
}

.chat {
	position: relative;
	display: flex;
	flex-direction: column;
	width: 90%;
	height: 80%;
	padding: 10px;
	overflow-y: auto;
}

.api-message {
	display: flex;
	margin-bottom: 14px;
	padding: 12px;
	border-radius: 10px;
	max-width: 75%;
	word-break: break-word;
	border: 1px solid #c5cddc;
}
.user-message {
	display: flex;
	align-items: center;
	margin-bottom: 10px;
	padding: 12px;
	border-radius: 10px;
	word-break: break-word;
	border: 1px solid #c5cddc;
	max-width: 65%;
}

.user-message {
	align-self: flex-end;
	background-color: white;
	color: #006064;
	text-align: right;
}

.api-message {
	align-self: flex-start;
	background-color: white;
	color: #0c306c;
	a {
		color: #1a73e8;
		text-decoration: underline;
		font-weight: bold;
		&:hover {
			color: #0c47b1;
			text-decoration: underline;
		}
	}
	ol {
		font-weight: bold;
		margin: 5px 0px 5px 20px;
		list-style-type: disclosure-closed;
	}
	ul {
		list-style-type: circle;
		margin: 5px 0px 5px 20px;
		li {
			margin-bottom: 10px;
		}
	}
}
.user-message img,
.api-message img {
	width: 30px;
	height: 30px;
	margin-right: 10px;
}

@keyframes fade-in {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}
.container-input-text {
	position: relative;
	background-color: #e1e1e1;
	border-radius: 16px;
	gap: 10px;
	padding: 8px;
	width: 100%;
	justify-content: center;
	align-items: center;
	display: flex;
	flex-direction: row;
}
@property --angle {
	syntax: '<angle>';
	initial-value: 0deg;
	inherits: false;
}
.loading-animation::after,
.loading-animation::before {
	content: '';
	position: absolute;
	width: 100%;
	height: 100%;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	background-image: conic-gradient(from var(--angle), transparent 10%, #1a4da1);
	border-radius: 16px;
	z-index: -1;
	padding: 27px;
	animation: 2s spin linear infinite;
}

.loading-animation::before {
	filter: blur(8px);
	opacity: 0.5;
}

@keyframes spin {
	0% {
		--angle: 0deg;
	}
	50% {
		--angle: 180deg;
	}
	100% {
		--angle: 360deg;
	}
}
.footer-gpt {
	position: fixed;
	bottom: 0;
	width: 110%;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: #f5f5f5;
	padding: 10px;
	z-index: 100;
}
.skeleton-container {
	width: 100%;
	padding: 16px;
	height: 100%;
}
.loading {
	display: flex;
	align-self: flex-start;
	align-items: center;
	margin-bottom: 10px;
	padding: 12px;
	border-radius: 10px;
	min-width: 75%;
	word-break: break-word;
	border: 1px solid #c5cddc;
	img {
		width: 30px;
		height: 30px;
		margin-right: 10px;
	}
}
.scroll-to-bottom {
	padding: 5px;
	position: fixed;
	bottom: 90px;
	right: 50%;
	font-size: 24px;
	width: 24px;
	height: 24px;
	color: #006064;
	cursor: pointer;
	transition: all 0.3s ease;
	opacity: 0.5;
	&:hover {
		bottom: 95px;
		opacity: 1;
	}
}
@media screen and (min-width: 1600px) {
	.user-message,
	.api-message {
		max-width: 65%;
	}
	.loading {
		min-width: 65%;
	}
	.chat {
		width: 70%;
	}
	.form {
		width: 50%;
	}
}
